import { FlowAPI, FlowEditorSDK, WidgetBuilder } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import applicationConfig from '../../../.application.json';

const { appDefinitionId } = applicationConfig;
export const getOrganizeMediaUrl = ({ instanceId, instance, compId }: any) => {
  return `https://progallery.wixapps.net/photos.html?instance_id=${instanceId}&instance=${instance}&compId=${compId}&origCompId=${compId}&appDefinitionId=${appDefinitionId}$app_id=${appDefinitionId}`;
};

const refreshAppServices = (editorSDK: FlowEditorSDK) => {
  return async () => {
    editorSDK.document.application.livePreview.refresh('', {
      shouldFetchData: true,
      source: '',
    });
    editorSDK.document.application.reloadManifest();
    const isDynamicPage = !!(await editorSDK.routers.getCurrentDynamicRouting(
      '',
    ));
    isDynamicPage && editorSDK.editor.routers.refresh('');
  };
};

const setDesktopGFPP = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  t: any,
) => {
  //  @ts-expect-error
  const instance = self?.pgBob?.instance;
  //  @ts-expect-error
  const instanceId = self?.pgBob?.instanceId;
  if (!instance) {
    return;
  }
  controllerBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('Mange Media'),
      onClick: ({ detail }) => {
        const compId = detail.componentRef.id;
        editorSDK.editor
          .openModalPanel('token', {
            url: getOrganizeMediaUrl({ instanceId, instance, compId }),
            title: t('Pro Gallery Media Manager'),
            width: 1254,
            height: 1000,
            shouldHideHeader: true,
          })
          .then((result) => {
            refreshAppServices(editorSDK)();
          });
      },
    })
    .set('design', {
      target: {
        role: 'proGallery1',
      },
    })
    .set('layout', {
      target: {
        role: 'proGallery1',
      },
    })
    .set('animation', {
      target: {
        role: 'proGallery1',
      },
    })
    .set('settings', {
      target: {
        role: 'proGallery1',
      },
    })
    //  @ts-expect-error
    .set('behavior', {
      target: {
        role: 'proGallery1',
      },
    });
};

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { t } = flowAPI.translations;
  controllerBuilder.set({
    displayName: 'Pro Gallery Bob',
    nickname: 'proGalleryWodget',
  });
  setDesktopGFPP(controllerBuilder, editorSDK, t);
};
